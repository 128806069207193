import { useStoreState } from "easy-peasy";
import AxiosWrapper from "./axios";
import Features from "./features";
import Wildcard from "./wildcard";

const SubmitApplicationService = () => {
  const Setting = useStoreState((state) => state.siteSettingsModel);
  const applicationResult = useStoreState((state) => state.applicationResultModel.applicationResult);

  const wildcard = new Wildcard();
  const client = new AxiosWrapper();
  const features = new Features();

  const convertKeysToLowerCase = (obj) => {
    let convertedObj = {};
    for (let key in obj) {
      if(typeof  convertedObj[key.toLowerCase()] === 'undefined'){
        convertedObj[key.toLowerCase()] = obj[key];
      }
    }
    return convertedObj;
  };

  const getData = (info) => {
    // This step is necessary to normalize the input. For some flows the info properties are in PascalCase
    // and some others in camelCase.
  
    const lowerCaseInfo = convertKeysToLowerCase(info);
    
    let applicantApiData = {
      Applicant: {
        FirstName: lowerCaseInfo.firstname,
        LastName: lowerCaseInfo.lastname,
        Suffix: lowerCaseInfo.suffix,
        Address1: lowerCaseInfo.address1,
        Address2: lowerCaseInfo.address2,
        City: lowerCaseInfo.city,
        State: lowerCaseInfo.state,
        Zip: lowerCaseInfo.zip,
        Email: lowerCaseInfo.email,
        PrimaryPhone: lowerCaseInfo.primaryphone,
        SecondaryPhone: lowerCaseInfo.secondaryphone,
        DOB: lowerCaseInfo.dob,
        SSN: lowerCaseInfo.ssn,
        MonthlyIncome: lowerCaseInfo.monthlyincome,
        IncomeSource: lowerCaseInfo.incomesource,
        ReservationCode: lowerCaseInfo.reservationcode
          ? lowerCaseInfo.reservationcode
          : "",
      },
      Terms: {
        ESignConsent: lowerCaseInfo.esignconsent === "1" ? true : false,
        OverlimitPrivilege:lowerCaseInfo.overlimitprivilege,
        CreditProtection:
          lowerCaseInfo.creditprotection === "yes" ? true : false,
        TcAcknowledgement:
          lowerCaseInfo.tcacknowledgement === "1" ? true : false,
        EmailOptIn: true,
        CardColorCode: lowerCaseInfo.cardcolorcode,
        SMSConsent: lowerCaseInfo.smsconsent,
        eConsentAcknowledgement : lowerCaseInfo.econsentacknowledgement,
      },
      AdditionalCardholder: {
        AdditionalCardholder:
          lowerCaseInfo.hasadditionalcardholder === "true" ? true : false,
        AdditionalCardholderFirstName:
          lowerCaseInfo.hasadditionalcardholder === "true" ? lowerCaseInfo.additionalcardholderfirstname || "" : "",
        AdditionalCardholderMI: lowerCaseInfo.hasadditionalcardholder === "true" ? lowerCaseInfo.additionalcardholdermi || "" : "",
        AdditionalCardholderLastName:
        lowerCaseInfo.hasadditionalcardholder === "true" ? lowerCaseInfo.additionalcardholderlastname || "" : "",
        AdditionalCardholderSuffix:
        lowerCaseInfo.hasadditionalcardholder === "true" ? lowerCaseInfo.additionalcardholdersuffix || "" : "",
      },
      SpouseInformation: {
        SpouseHasDifferentAddress:
          lowerCaseInfo.spousehasdifferentaddress || "false",
        SpouseFirstName: lowerCaseInfo.spousefirstname || "",
        SpouseMiddleName: lowerCaseInfo.spousemiddleinitial || "",
        SpouseLastName: lowerCaseInfo.spouselastname || "",
        SpouseSuffix: lowerCaseInfo.spousesuffix || "",
        SpouseAddress1: lowerCaseInfo.spouseaddress1 || "",
        SpouseAddress2: lowerCaseInfo.spouseaddress2 || "",
        SpouseCity: lowerCaseInfo.spousecity || "",
        SpouseState: lowerCaseInfo.spousestate || "",
        SpouseZip: lowerCaseInfo.spousezip || "",
        SpouseEmail: lowerCaseInfo.spouseemail || "",
        IsMarried: lowerCaseInfo.ismarried || "false",
      },
      Tracking: {
        SessionId: sessionStorage.getItem("oldSessionToken"),
        ReportId: sessionStorage.getItem("ReportId"),
        UtmInfoId: sessionStorage.getItem("trackingId") || "",
        SrcId: "",
        SID: client.findParam("sid") || "",
        PubId:
          client.findParam("pub") ||
          client.findParam("pubid") ||
          Setting.productSettings.PubId,
        SubId: lowerCaseInfo.subid,
        HID: client.findParam("hid") || "",
        C1: client.findParam("c1") || "",
        C2: client.findParam("c2") || "",
        C3: Setting.mainSettings.c3 || "",
        Gclid: JSON.parse(sessionStorage.getItem("trafficInfo"))["Provider"] === "Google" ? JSON.parse(sessionStorage.getItem("trafficInfo"))["ClickId"]
            : "",
        GAdsExpirationDate: JSON.parse(sessionStorage.getItem("trafficInfo"))["Provider"] === "Google" ? lowerCaseInfo.gadsexpirationdate : "",
        Fbclid: client.findParam("fbclid") || "",
        FbAdsExpirationDate: client.findParam("fbclid") ? lowerCaseInfo.fbadsexpirationdate : "",
        Msclid: client.findParam("msclid") || "",
        MsAdsExpirationDate: client.findParam("msclid") ? lowerCaseInfo.msadsexpirationdate : "",
        TransunionBlackBox: sessionStorage.getItem("blackBox") || "",
      },
      SiteSettings: {
        WebSite: Setting.domain,
        EngineID: 0,
        IsPrequal:
          Setting.mainSettings.Flow.toUpperCase() === "PREQUAL" ? true : false,
        PID: Setting.productSettings.PID,
        ProductCode: lowerCaseInfo.productcode,
        ProductId: Setting.productSettings.ProductId,
        AppRef: Setting.productSettings.ApplicationSourceReference
          ? Setting.productSettings.ApplicationSourceReference
          : "",
        Step: lowerCaseInfo.step,
        TermsId: Setting.mainSettings.TermsId,
        Token: client.findParam("token") || "",
        Flow: Setting.mainSettings.Flow.toUpperCase(),
        AFJRef : (Setting.mainSettings.Flow.toUpperCase() === 'PREQUAL') ? false : wildcard.isFeatureEnabled('MFAActive')
      },
      PendUser: features.sendPendUser()
      
    };

    // Different properties and data types must be handled specifically for Cross Sell and cannot
    // be send extra properties or else the endpoint will fail.
    if (Setting.mainSettings.Flow.toUpperCase() === "CS") {
      applicantApiData.Applicant.MiddleInitial = lowerCaseInfo.middlename;
      // In Cross Sell this property is already a boolean
      applicantApiData.Applicant.cashIntent = lowerCaseInfo.cashintent;
    } else {
      applicantApiData.Applicant.MiddleName = lowerCaseInfo.middlename;
      applicantApiData.Applicant.cashIntent =
        lowerCaseInfo.cashintent === "" ? false : lowerCaseInfo.cashintent;
      applicantApiData.Applicant.HasCheckingAccount =
        lowerCaseInfo.hascheckingaccount;
      applicantApiData.HasCardColorChoice =
        Setting.mainSettings.HasCardColorChoice;
    }
    return applicantApiData;
  };

  const submitApplication = async (info, fullResponse = false) => {
    const applicantApiData = getData(info);
    const response = await client.post("submit-application", applicantApiData, fullResponse);
    return response;
  };

  const submitApplicationOffer = async (info) => {
    let submitApplicationOfferPayload = {
      ApplicationId: applicationResult.ref_number, // should come from submitAPplication result
      ReportId: sessionStorage.getItem("ReportId"),
      UtmInfoId: sessionStorage.getItem("trackingId") || "",
      Initials: "", //leave this blank
      CreditProtection: info.creditProtection === "no" ? false : true,
      CardColorCode: info.cardColorCode, // leave this blank for prequal reflex
      HasCardColorChoice: Setting.mainSettings.HasCardColorChoice,
      AgreeAcknowledgements: info.generalAcknowledgement === "1" ? true : false,
      SiteId: "https://" + window.location.hostname,
      smsConsent: info.SMSConsent,
      primaryPhone: info.PrimaryPhone,
      secondaryPhone: info.SecondaryPhone,
      eConsentAcknowledgement : info.eConsentAcknowledgement,
      AFJRef :  wildcard.isFeatureEnabled('MFAActive'),
      Website :  Setting.domain 
    };
    return await client.post("submit-application-offer", submitApplicationOfferPayload);
  };

  return {
    submitApplication,
    submitApplicationOffer,
  };
};

export default SubmitApplicationService;
